import React from "react"
export default function IconBasket() {
  return (
    <svg viewBox="0 0 33 33">
      <path
        d="M24.0271 9.14004H16.3789C16.0719 9.14004 15.7775 9.2612 15.5604 9.47687C15.3434 9.69254 15.2214 9.98505 15.2214 10.29C15.2214 10.5951 15.3434 10.8876 15.5604 11.1032C15.7775 11.3189 16.0719 11.4401 16.3789 11.4401H24.0271C24.1231 11.4392 24.2181 11.4594 24.3053 11.4993C24.3926 11.5391 24.4699 11.5976 24.5318 11.6705C24.5937 11.7434 24.6386 11.829 24.6634 11.9212C24.6882 12.0133 24.6922 12.1098 24.6752 12.2037L22.9206 20.5619C22.8944 20.7126 22.8154 20.8493 22.6977 20.9478C22.5799 21.0462 22.431 21.1002 22.277 21.1001H14.777C14.6231 21.1002 14.4741 21.0462 14.3563 20.9478C14.2386 20.8493 14.1597 20.7126 14.1335 20.5619L11.5733 5.92001C11.5217 5.66023 11.3808 5.42637 11.1747 5.25852C10.9686 5.09066 10.7101 4.99927 10.4436 5H5.15654C4.98017 5.00014 4.80617 5.04032 4.64782 5.11747C4.48947 5.19463 4.35095 5.30672 4.24286 5.44519C4.13477 5.58365 4.05995 5.74483 4.02413 5.91642C3.9883 6.088 3.99242 6.26545 4.03616 6.43522C4.10651 6.68932 4.26061 6.91263 4.4738 7.06942C4.687 7.2262 4.94696 7.3074 5.21209 7.30002H9.49917L11.8696 20.9529C11.9889 21.6347 12.3453 22.2531 12.8767 22.7004C13.408 23.1476 14.0806 23.3953 14.777 23.4002H22.277C22.9766 23.3996 23.6536 23.1539 24.1888 22.7063C24.724 22.2586 25.0831 21.6378 25.203 20.9529L26.9576 12.5901C27.0304 12.1663 27.0092 11.7318 26.8955 11.317C26.7817 10.9022 26.5782 10.517 26.2991 10.1885C26.0201 9.85994 25.6721 9.59589 25.2797 9.4148C24.8873 9.23371 24.4598 9.13994 24.0271 9.14004V9.14004Z"
        fill="#080708"
      />
      <path
        d="M20.8397 15.5803C21.1466 15.5803 21.441 15.4591 21.6581 15.2435C21.8751 15.0278 21.9971 14.7353 21.9971 14.4303C21.9971 14.1253 21.8751 13.8328 21.6581 13.6171C21.441 13.4014 21.1466 13.2803 20.8397 13.2803H17.1359C16.829 13.2803 16.5346 13.4014 16.3175 13.6171C16.1005 13.8328 15.9785 14.1253 15.9785 14.4303C15.9785 14.7353 16.1005 15.0278 16.3175 15.2435C16.5346 15.4591 16.829 15.5803 17.1359 15.5803H20.8397Z"
        fill="#080708"
      />
      <path
        d="M15.2864 27.9998C16.1813 27.9998 16.9068 27.279 16.9068 26.3898C16.9068 25.5006 16.1813 24.7798 15.2864 24.7798C14.3915 24.7798 13.666 25.5006 13.666 26.3898C13.666 27.279 14.3915 27.9998 15.2864 27.9998Z"
        fill="#080708"
      />
      <path
        d="M21.7669 27.9998C22.6618 27.9998 23.3873 27.279 23.3873 26.3898C23.3873 25.5006 22.6618 24.7798 21.7669 24.7798C20.872 24.7798 20.1465 25.5006 20.1465 26.3898C20.1465 27.279 20.872 27.9998 21.7669 27.9998Z"
        fill="#080708"
      />
      <path
        d="M21.073 18.11C21.073 17.805 20.9511 17.5125 20.734 17.2968C20.517 17.0811 20.2226 16.96 19.9156 16.96H17.6008C17.2938 16.96 16.9994 17.0811 16.7824 17.2968C16.5653 17.5125 16.4434 17.805 16.4434 18.11C16.4434 18.415 16.5653 18.7075 16.7824 18.9231C16.9994 19.1388 17.2938 19.26 17.6008 19.26H19.9156C20.2226 19.26 20.517 19.1388 20.734 18.9231C20.9511 18.7075 21.073 18.415 21.073 18.11V18.11Z"
        fill="#FBDCCE"
      />
    </svg>
  )
}
