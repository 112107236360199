import React from "react"
export default function LogoCrystallize() {
  return (
    <svg version="1.1" viewBox="0 0 317.1 143.5">
      <g>
        <g>
          <path
            fill="#717070"
            d="M113.9,86.3c1.2,0,2.3-0.3,3.3-0.8c1-0.6,1.8-1.3,2.4-2.3L122,85c-0.9,1.3-2.1,2.3-3.5,3s-2.9,1.1-4.6,1.1
			c-1.4,0-2.7-0.3-3.9-0.8c-1.2-0.5-2.3-1.3-3.2-2.2s-1.6-2-2.1-3.2s-0.8-2.5-0.8-4c0-1.4,0.3-2.7,0.8-4c0.5-1.2,1.2-2.3,2.1-3.2
			s2-1.6,3.2-2.2s2.5-0.8,3.9-0.8c1.7,0,3.2,0.4,4.6,1.1c1.4,0.7,2.6,1.7,3.5,3l-2.4,1.8c-0.6-0.9-1.4-1.7-2.4-2.3
			c-1-0.6-2.1-0.8-3.3-0.8c-1,0-1.9,0.2-2.7,0.6c-0.8,0.4-1.6,0.9-2.2,1.6c-0.6,0.7-1.1,1.4-1.5,2.3c-0.4,0.9-0.6,1.8-0.6,2.9
			s0.2,2,0.6,2.9c0.4,0.9,0.9,1.7,1.5,2.3c0.6,0.7,1.4,1.2,2.2,1.6C112,86.1,112.9,86.3,113.9,86.3z"
          />
          <path
            fill="#717070"
            d="M140.3,78c-0.3,0.8-0.6,1.6-1.2,2.2c-0.5,0.7-1.1,1.2-1.9,1.7c0,0-0.1,0.1-0.1,0.1s-0.1,0-0.1,0.1l3.6,7h-3.1
			l-3.1-6.1c-0.2,0-0.4,0.1-0.7,0.1c-0.2,0-0.5,0-0.7,0h-3.6v6h-3V68.8h6.6c0.8,0,1.6,0.1,2.3,0.3c0.7,0.2,1.4,0.5,2,0.9
			c1.5,0.9,2.5,2.3,3,4c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.2,0.1,0.2v0.1c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3v0c0,0.1,0,0.2,0,0.3
			c0,0.1,0,0.2,0,0.4v0V76c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3v0.1c0,0.1,0,0.2,0,0.3s0,0.2-0.1,0.3v0.1C140.4,77.7,140.3,77.9,140.3,78z
      M133.3,80.5c0.6,0,1.2-0.1,1.7-0.4c0.5-0.3,0.9-0.6,1.3-1c0.4-0.4,0.7-0.9,0.9-1.5s0.3-1.1,0.3-1.7c0-0.6-0.1-1.1-0.3-1.7
			c-0.2-0.6-0.5-1-0.9-1.5c-0.4-0.4-0.8-0.8-1.3-1c-0.5-0.3-1.1-0.4-1.7-0.4h-4v9.1H133.3z"
          />
          <path
            fill="#717070"
            d="M160.5,68.8L160.5,68.8l-6.3,13.7v6.7v0h-3v0v-6.7L145,68.8h0l0,0h3.3l4.5,10.9l4.5-10.9H160.5z"
          />
          <path
            fill="#717070"
            d="M176.1,79c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.5,0.8,1.1,1,1.8s0.4,1.3,0.4,2
			c0,0.2,0,0.3,0,0.4c-0.1,1-0.4,2-0.9,2.8c-0.5,0.8-1.2,1.4-2,1.8s-1.7,0.7-2.7,0.7h-1.8c-1,0-1.9-0.2-2.7-0.7s-1.5-1.1-2-1.8
			c-0.1-0.2-0.3-0.5-0.4-0.8l2.6-2c0.1,0.4,0.2,0.7,0.3,1c0.2,0.3,0.4,0.6,0.7,0.8s0.6,0.4,0.9,0.5c0.3,0.1,0.7,0.2,1.1,0.2h1.1
			c0.8,0,1.4-0.2,2-0.7c0.6-0.5,0.9-1.1,1-1.8c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.4-0.1-0.8-0.2-1.1c-0.1-0.4-0.4-0.7-0.6-0.9
			c-0.3-0.3-0.6-0.5-0.9-0.7c-0.4-0.2-0.7-0.3-1.1-0.3h-1.6h-0.1c-0.7,0-1.3-0.2-1.9-0.4c-0.6-0.2-1.1-0.6-1.6-1
			c-0.3-0.2-0.5-0.4-0.7-0.7c-0.4-0.5-0.8-1.1-1-1.8s-0.4-1.3-0.4-2v-0.4c0.1-1.1,0.4-2,1-2.8c0.5-0.8,1.2-1.4,2-1.8
			c0.8-0.5,1.7-0.7,2.7-0.7h1.8c1,0,1.9,0.2,2.7,0.7c0.8,0.5,1.5,1.1,2,1.8c0.1,0.1,0.1,0.2,0.2,0.3s0.1,0.2,0.2,0.3l-2.5,2
			c-0.2-0.7-0.5-1.2-1.1-1.7c-0.5-0.4-1.2-0.7-1.9-0.7H171c-0.4,0-0.7,0.1-1.1,0.2c-0.3,0.1-0.6,0.3-0.9,0.5
			c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.3-0.3,0.6-0.3,1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.4,0.1,0.8,0.2,1.1
			c0.1,0.4,0.4,0.7,0.6,0.9c0.3,0.3,0.6,0.5,0.9,0.7c0.4,0.2,0.7,0.3,1.1,0.3h1.6h0.1c0.7,0,1.3,0.2,1.9,0.4
			C175,78.2,175.6,78.6,176.1,79z"
          />
          <path
            fill="#717070"
            d="M182.5,68.8h13v2.9h-5v17.4h-2.9V71.7h-5V68.8z"
          />
          <path
            fill="#717070"
            d="M199.8,89.1l8.3-20.3l8.3,20.3h-3l-0.6-1.6h-9.3l-0.6,1.6H199.8z M204.5,84.9h7.3l-3.6-9.3L204.5,84.9z"
          />
          <path fill="#717070" d="M223.8,86.2h8.3v2.9h-11.3V68.8h3V86.2z" />
          <path fill="#717070" d="M239.4,86.2h8.3v2.9h-11.3V68.8h3V86.2z" />
          <path fill="#717070" d="M252.1,68.8h3v20.3h-3V68.8z" />
          <path
            fill="#717070"
            d="M272.4,68.8l-8.3,17.4h8.2v0v2.9v0h-12.9v0l8.2-17.5h-7.1v-2.9v0L272.4,68.8L272.4,68.8L272.4,68.8z"
          />
          <path
            fill="#717070"
            d="M279.7,86.2h9v2.9h-12V68.8h11.8v2.9h-8.8v3.2v1.7v2h8v2.8h-8V86.2z"
          />
        </g>
        <polygon
          fill="#A5D9DC"
          points="74.8,66.4 62.2,115.2 45.9,104.8 58.5,28.3 76.9,58.2 	"
        />
        <polygon
          fill="#D2ECED"
          points="39.9,67.9 35.6,98.2 33.2,96.6 28.3,61.4 	"
        />
        <polygon
          fill="#BBE2E5"
          points="53.7,47.7 44.5,103.9 37.1,99.1 46.1,36 	"
        />
        <polygon
          fill="#8ECFD3"
          points="86.9,64.2 76.4,104.4 69.5,110.1 64,114.6 75.8,69 	"
        />
      </g>
    </svg>
  )
}
